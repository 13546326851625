<template>
  <div
    tabindex="0"
    :class="[
      'overflow-y-auto overflow-x-hidden fixed top-2 sm:top-28 right-0 left-0 m-auto w-full md:inset-0 h-modal md:h-full justify-center items-center flex z-[6]',
      isShowModal ? 'block' : 'hidden',
    ]"
    @click="closeModal"
  >
    <div class="relative p-4 w-full h-full max-w-3xl mt-28">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div
          class="p-4 rounded-t flex justify-between items-center border-b border-gray-200 dark:border-gray-600"
        >
          <div>
            <h3 class="capitalize text-xl !text-[#dc3545] font-[Inter]">
              Thông Báo : Sale Tưng Bừng Ngày Tết Nguyên Đán
            </h3>
          </div>
          <button
            aria-label="close"
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white close"
            @click="closeModal"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div class="p-6 flex flex-col gap-3">
          <!-- <div>
            <img
              src="/assets/img/img-sale.png"
              alt=""
              width="100%"
              class="h-52 rounded aspect-video"
            />
          </div> -->
          <div class="">
            <div class="text-lg mb-4">
              <div class="flex flex-col gap-5 items-center justify-center mb-2">
                <span class="flex ms-2">
                  <input
                    type="text"
                    class="rounded-s col-8 w-1/2 sm:w-64 col-sm-12 border border-green-400 hover:border-green-400 focus:border-green-400"
                    value="GIAMGIA5KTHANG01"
                    readonly
                  />

                  <button
                    @click="copyText('GIAMGIA5KTHANG01')"
                    class="text-white bg-green-400 p-2 rounded-e"
                  >
                    Copy mã 1
                  </button>
                </span>
                <span class="flex ms-2">
                  <input
                    type="text"
                    class="rounded-s col-8 w-1/2 sm:w-64 col-sm-12 border border-green-400 hover:border-green-400 focus:border-green-400"
                    value="GIAMGIA10KTHANG01"
                    readonly
                  />

                  <button
                    @click="copyText('GIAMGIA10KTHANG01')"
                    class="text-white bg-green-400 p-2 rounded-e"
                  >
                    Copy mã 2
                  </button>
                </span>
                <span class="flex ms-2">
                  <input
                    type="text"
                    class="rounded-s col-8 w-1/2 sm:w-64 col-sm-12 border border-green-400 hover:border-green-400 focus:border-green-400"
                    value="GIAMGIA20KTHANG01"
                    readonly
                  />

                  <button
                    @click="copyText('GIAMGIA20KTHANG01')"
                    class="text-white bg-green-400 p-2 rounded-e"
                  >
                    Copy mã đặt biệt
                  </button>
                </span>
              </div>
              <div>
                <p>Nhập mã 1 <strong>giảm 5k</strong> cho đơn hàng trên 50k</p>
                <p>
                  Nhập mã 2 <strong>giảm 10k</strong> cho đơn hàng trên 150k
                </p>
                <p>
                  Nhập mã đặc biệt <strong>giảm 20k</strong> cho đơn hàng trên
                  300k
                </p>

                <strong
                  >Tất cả mã giảm giá áp dụng từ ngày 29/01/2025 -
                  06/02/2025</strong
                >
              </div>
            </div>
            <p class="text-center">
              <span
                ><span style="font-size: 18px"
                  ><em
                    >Cảm ơn bạn vì đã chọn chúng tôi. Hy vọng bạn sẽ có trải
                    nghiệm tuyệt vời khi sử dụng sản phẩm dịch vụ của chúng tôi.
                    Rất mong sẽ tiếp tục nhận được sự ủng hộ của bạn trong thời
                    gian tới</em
                  ></span
                ></span
              >
            </p>
          </div>
        </div>
        <div
          class="p-6 rounded-b border-gray-200 border-t dark:border-gray-600"
        >
          <div class="flex flex-col gap-3">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

const $toast = useToast()
$toast.clear()

const props = defineProps(['exits_notification'])
const isShowModal = ref(props.exits_notification)

function closeModal(event) {
  if (event.target === event.currentTarget || event.target.closest('.close')) {
    isShowModal.value = false
  }
}

const copyText = async (textToCopy) => {
  try {
    await navigator.clipboard.writeText(textToCopy)
    $toast.success('Đã sao chép văn bản vào bảng nhớ tạm')
  } catch (err) {
    $toast.error('Đa ', err)
  }
}
</script>
